export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    perfil_id: [1, 2, 5, 6, 7, 10, 15, 11, 21],
  },
  {
    title: 'Cadastros',
    icon: 'FileTextIcon',
    perfil_id: [2],
    children: [
      {
        title: 'Usuários',
        route: 'list-users',
        perfil_id: [2],
      },
    ],
  },

]
